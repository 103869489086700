<template>
    <div class="container">
        <h1>{{$t('profile.heading')}}</h1>
        <p>{{$t('profile.subtitle')}}</p>

        <div class="row mt-4">
            <div class="col-12 col-lg-6">
                <user-data-card />
                <phone-number-changer class="mt-4" />
            </div>
            <div class="col-12 col-lg-6">
                <others-card />
                <password-changer class="mt-4"/>
            </div>
        </div>
    </div>
</template>

<script>
import OthersCard from './Components/OthersCard.vue'
import PasswordChanger from './Components/PasswordChanger.vue'
import PhoneNumberChanger from './Components/PhoneNumberChanger.vue'
import UserDataCard from './Components/UserDataCard.vue'

export default {
    name: 'Profile',
    components: {
        UserDataCard,
        PhoneNumberChanger,
        PasswordChanger,
        OthersCard
    }
}
</script>

<i18n>
{
    "cz": {
        "profile": {
            "heading": "Profil uživatele",
            "subtitle": "V profilu uživatele si můžete změnit základní údaje svého účtu, nebo nastavit nové heslo. Požadavek na změnu emailu a tel. čísla je nutné autorizovat."
        }
    },
    "en": {
        "profile": {}
    }
}
</i18n>

<template>
    <div class="bg-white shadow-sm cyrrus-rounded py-4">
        <h3 class="px-4">{{$t('userDataCard.title')}}</h3>
        <ul class="card-list" v-if="!showForm">
            <li>
                <div class="mr-2 text-primary">
                    <strong>{{$t('userDataCard.firstAndLastName')}}:</strong>
                </div>
                <div>
                    {{user.firstName}} {{user.lastName}}
                </div>
            </li>
            <li>
                <div class="mr-2 text-primary">
                    <strong>{{$t('userDataCard.email')}}:</strong>
                </div>
                <div>
                    {{user.email}}
                </div>
            </li>
            <li>
                <div class="mr-2 text-primary">
                    <strong>{{$t('userDataCard.phoneNumber')}}:</strong>
                </div>
                <div>
                    {{user.phoneNumber}}
                </div>
            </li>
        </ul>

        <loader-overlay v-else :loading="loading">
            <b-form
                class="px-4"
                @submit.prevent="onSubmit"
            >
                <form-input
                    :label="$t('userDataCard.firstName')"
                    :id="'userDataCard.firstName'"
                    v-model="firstName"
                    :validation="$v.firstName"
                />
                <form-input
                    :label="$t('userDataCard.lastName')"
                    :id="'userDataCard.lastName'"
                    v-model="lastName"
                    :validation="$v.lastName"
                />
                <form-input
                    :label="$t('userDataCard.email')"
                    :id="'userDataCard.email'"
                    v-model="email"
                    :validation="$v.email"
                    :hint="$t('userDataCard.emailHint')"
                />
                <form-input
                    :formatter="formatPhoneNumber"
                    :label="$t('userDataCard.phoneNumber')"
                    :id="'userDataCard.phoneNumber'"
                    v-model="phoneNumber"
                    :validation="$v.phoneNumber"
                    :hint="$t('userDataCard.phoneNumberHint')"
                    :validation-messages="{ phoneNumber: $t('userDataCard.phoneNumberInvalid') }"
                />

                <div v-for="(error, index) in errors" :key="`error-${index}`" class="alert alert-danger mb-3">
                    {{error}}
                </div>

                <div class="mt-4">
                    <b-btn
                        variant="primary"
                        type="submit"
                        size="sm"
                        class="mr-3"
                    >
                        <span class="iconify mr-2" data-icon="mdi:content-save"></span>{{$t('userDataCard.submit')}}
                    </b-btn>
                    <b-btn
                        variant="white"
                        @click.prevent="showForm = false"
                        size="sm"
                    >
                        <span class="iconify mr-2" data-icon="mdi:close"></span>{{$t('userDataCard.cancel')}}
                    </b-btn>
                </div>
            </b-form>
        </loader-overlay>

        <div class="mt-4 px-4" v-if="!showForm">
            <b-btn
                variant="primary"
                @click.prevent="onEdit"
                size="sm"
            >
                <span class="iconify mr-2" data-icon="mdi:pencil"></span>{{$t('userDataCard.edit')}}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { parsePhoneNumberFromString, parseIncompletePhoneNumber } from 'libphonenumber-js/mobile'

export default {
    name: 'UserDataCard',
    data () {
        return {
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            showForm: false,
            loading: false,
            errors: []
        }
    },
    computed: {
        user () {
            return this.$user
        }
    },
    methods: {
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('userDataCard.formError'))
                return this.$v.$touch()
            }
            this.loading = true
            this.errors = []
            this.$api.profile.updateProfile({
                firstName: `${this.firstName}`,
                lastName: `${this.lastName}`,
                email: `${this.email}`,
                phoneNumber: `${this.phoneNumber}`
            }).then((response) => {
                this.$notify.success(this.$t('userDataCard.formSuccess'))
                if (response.data.email === 'notUnique') {
                    this.errors.push(this.$t('userDataCard.emailNotUnique'))
                }
                if (response.data.email !== this.user.email) {
                    this.$notify.success(this.$t('userDataCard.emailChanged'))
                }
                if (response.data.phoneNumber !== this.user.phoneNumber) {
                    this.$notify.success(this.$t('userDataCard.phoneNumberChanged'))
                }
            }).catch((error) => {
                console.error(error)
                this.errors.push(this.$t('userDataCard.formSubmitError'))
                this.$notify.error(this.$t('userDataCard.formSubmitError'))
            }).finally(() => {
                this.$nextTick(() => {
                    this.$user.reload()
                    this.loading = false
                    if (this.errors.length === 0) {
                        this.showForm = false
                    }
                })
            })
        },
        onEdit () {
            this.firstName = `${this.user.firstName}`
            this.lastName = `${this.user.lastName}`
            this.email = `${this.user.email}`
            this.phoneNumber = `${this.user.phoneNumber}`
            this.showForm = true
        },
        formatPhoneNumber (number) {
            if (number) {
                return parseIncompletePhoneNumber(number)
            }
            return number
        }
    },
    validations: {
        email: {
            required,
            email
        },
        phoneNumber: {
            required,
            phoneNumber: (value) => {
                if (!value) {
                    return false
                }
                const number = parsePhoneNumberFromString(`${value}`)
                if (number && number.isValid()) {
                    return true
                }
                return false
            }
        },
        firstName: {
            required
        },
        lastName: {
            required
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "userDataCard": {
            "title": "Osobní údaje",
            "firstAndLastName": "Jméno a příjmení",
            "email": "Email",
            "emailHint": "Změnu emailu bude nutné potvrdit klikem na odkaz v kontrolní zprávě.",
            "phoneNumber": "Telefon",
            "phoneNumberHint": "Změnu telefonního čísla bude nutné potvrdit pomocí SMS.",
            "phoneNumberInvalid": "Chybný formát tel. čísla. Uveďte mobilní číslo ve formátu s národní předvolbou např. +420XXXXXXXXX",
            "edit": "Upravit",
            "firstName": "Křestní jméno",
            "lastName": "Příjmení",
            "submit": "Uložit",
            "cancel": "Zrušit",
            "formError": "Některá pole formuláře jsou chybně vyplněná.",
            "formSubmitError": "Změny se nepodařilo uložit.",
            "formSuccess": "Změny byly uloženy.",
            "emailNotUnique": "Požadovaný email je už registrován u jiného uživatele, použijte prosím jiný.",
            "phoneNumberChanged": "Byla vám odeslána SMS s kódem pro potvrzení změny telefonního čísla, který prosím zadejte do formuláře níže.",
            "emailChanged": "Byl vám odeslán email s odkazem pro potvrzení změny emailové adresy."
        }
    },
    "en": {
        "userDataCard": {
            "title": "Personal data",
            "firstAndLastName": "Name and Surname",
            "email": "Email",
            "phoneNumber": "Phone number",
            "edit": "Edit",
            "firstName": "First name",
            "lastName": "Last name"
        }
    }
}
</i18n>
